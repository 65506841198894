import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// Component
import ProjectHero from "../../section/projectpage/projectpage-hero"
import { BlockFullImage, BlockWideImage } from "../../components/project/image"
import Divider from "../../components/divider"
import ProjectAbout from "../../section/projectpage/projectpage-about"
import {
  OneColumnHeading,
  OneColumnTitle,
  OneColumnElementTitle,
  OneColumnElementUnorderList,
  OneColumnElementOrderList,
  OneColumnElementListItem,
} from "../../components/project/one-column"
import {
  TwoColumnHeading,
  TwoColumnTitle,
} from "../../components/project/two-column"
import Quote from "../../components/project/quote"
import { Display, DisplaySizePlus } from "../../components/project/display"
import ProjectNext from "../../section/projectpage/projectpage-next"
import Video from "../../components/project/video"
// Layout
import LayoutWrapper from "../../layouts/wrapper"
import Metadata from "../../layouts/metadata"
import Header from "../../layouts/header"
import { Container, ProjectContainer } from "../../layouts/container"
import Footer from "../../layouts/footer"
import Social from "../../layouts/social"

// Data
import useRutenDesignSystemData from "../../hook/projects/ruten-design-system"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"

export default function Template() {
  // Data
  const {
    about_content,
    about_title,
    hero_image,
    project_description,
    project_name,
    role,
    timeline,
    tool,
    type,
    order1,
    body,
    body1,
  } = useRutenDesignSystemData()

  const ProjectHeroData = {
    num: `0${Number(order1.text)}`,
    image_url: hero_image.fluid,
    name: project_name.raw,
    description: project_description.text,
  }
  const ProjectAboutData = {
    subtitle: "Overview",
    title: about_title.text,
    content: about_content.text,
    timeline: timeline.text,
    type: type.text,
    role: role.text,
    tool: tool.text,
  }
  const SliceData = body
  const SliceData2 = body1

  const ProblemsData = {
    quote: SliceData[0].primary.quote.text,
    num: SliceData[1].primary.num.text,
    heading: SliceData[1].primary.heading.text,
    title: SliceData[2].primary.heading.text,
    listTitle: SliceData[2].items[0].title.text,
    listContent: SliceData[2].items[0].content.text,
    listTitle2: SliceData[2].items[1].title.text,
    listContent2: SliceData[2].items[1].content.text,
    listTitle3: SliceData[2].items[2].title.text,
    listContent3: SliceData[2].items[2].content.text,
  }

  const RoleData = {
    num: SliceData[3].primary.num.text,
    heading: SliceData[3].primary.heading.text,
    content: SliceData[3].items[0].content.text,
    title: SliceData[4].primary.heading.text,
    listTitle: SliceData[4].items[0].title.text,
    listContent: SliceData[4].items[0].content.text,
    listTitle2: SliceData[4].items[1].title.text,
    listContent2: SliceData[4].items[1].content.text,
  }

  const ToolsSketchData = {
    image_url: SliceData[6].items[0].full_image.fluid,
    image_alt: SliceData[6].items[0].full_image.alt,
    num: SliceData[5].primary.num.text,
    heading: SliceData[5].primary.heading.text,
    content: SliceData[5].items[0].content.text,
    title: SliceData[5].items[1].title.text,
    content2: SliceData[5].items[1].content.text,
  }

  const ToolsSystemData = {
    title: SliceData[5].items[2].title.text,
    content: SliceData[5].items[2].content.text,
    image_url: SliceData[7].items[0].image.fluid,
    image_alt: SliceData[7].items[0].image.alt,
    listTitle: SliceData[5].items[3].title.text,
    listContent: SliceData[5].items[3].content.text,
    listTitle2: SliceData[5].items[4].title.text,
    listContent2: SliceData[5].items[4].content.text,
    listTitle3: SliceData[5].items[5].title.text,
    listContent3: SliceData[5].items[5].content.text,
    listTitle4: SliceData[5].items[6].title.text,
    listContent4: SliceData[5].items[6].content.text,
  }

  const ToolsZeroData = {
    content: SliceData[5].items[7].content.text,
    title: SliceData[5].items[7].title.text,
    image_url: SliceData[7].items[1].image.fluid,
    image_alt: SliceData[7].items[1].image.alt,
    image_url2: SliceData[7].items[2].image.fluid,
    image_alt2: SliceData[7].items[2].image.alt,
  }

  const DesignLabelData = {
    num: SliceData[8].primary.num.text,
    heading: SliceData[8].primary.heading.text,
    title: SliceData[8].items[0].title.text,
    image_url: SliceData[9].items[0].image.fluid,
    image_alt: SliceData[9].items[0].image.alt,
  }

  const DesignResearchData = {
    content: SliceData[10].items[0].content.text,
    title: SliceData[10].items[0].title.text,
  }

  const DesignDefhData = {
    content: SliceData[10].items[1].content.text,
    title: SliceData[10].items[1].title.text,
    content2: SliceData[10].items[2].content.text,
    image_url: SliceData[11].items[0].image.fluid,
    image_alt: SliceData[11].items[0].image.alt,
  }

  const DesignLibData = {
    content: SliceData[12].items[0].content.text,
    title: SliceData[12].items[0].title.text,
    content2: SliceData[12].items[1].content.text,
    image_url: SliceData[13].items[0].image.fluid,
    image_alt: SliceData[13].items[0].image.alt,
    image_url2: SliceData[13].items[1].image.fluid,
    image_alt2: SliceData[13].items[1].image.alt,
    image_url3: SliceData[14].items[0].full_image.fluid,
    image_alt3: SliceData[14].items[0].full_image.alt,
  }

  const DesignTestData = {
    content: SliceData[15].items[0].content.text,
    title: SliceData[15].items[0].title.text,
    content2: SliceData[15].items[1].content.text,
    image_url: SliceData[16].items[0].image.fluid,
    image_alt: SliceData[16].items[0].image.alt,
    image_url2: SliceData[16].items[1].image.fluid,
    image_alt2: SliceData[16].items[1].image.alt,
    image_url3: SliceData[16].items[2].image.fluid,
    image_alt3: SliceData[16].items[2].image.alt,
    image_url4: SliceData[16].items[3].image.fluid,
    image_alt4: SliceData[16].items[3].image.alt,
  }

  const DesignNextData = {
    content: SliceData[17].items[0].content.text,
    title: SliceData[17].items[0].title.text,
  }

  const LearnData = {
    num: SliceData2[0].primary.num.text,
    heading: SliceData2[0].primary.heading.text,
    title: SliceData2[0].items[0].title.text,
    content: SliceData2[0].items[0].content.text,
    content2: SliceData2[0].items[1].content.text,
    content3: SliceData2[0].items[2].content.text,
  }

  // Animation
  // useEffect(() => {
  //   const layoutWrapper = document.querySelector("#layoutWrapper")
  //   const bgColorChange = document.querySelector("#bgColorChange")

  //   gsap.timeline({
  //     ease: "none",
  //     scrollTrigger: {
  //       id: "bgColorChange",
  //       trigger: bgColorChange,
  //       start: "top center",
  //       end: "bottom top",
  //       markers: false,
  //       toggleClass: {
  //         targets: layoutWrapper,
  //         className: "bg-gray-500",
  //       },
  //     },
  //   })

  //   return () => {
  //     if (ScrollTrigger.getById("bgColorChange")) {
  //       ScrollTrigger.getById("bgColorChange").kill()
  //     }
  //   }
  // }, [])

  return pug`
    Metadata
    Header
    Social
    main
      ProjectHero(num=ProjectHeroData.num image_url=ProjectHeroData.image_url name=ProjectHeroData.name description=ProjectHeroData.description)
      Container.flex.justify-center.items-center.flex-col
        // About
        ProjectAbout(subtitle=ProjectAboutData.subtitle title=ProjectAboutData.title content=ProjectAboutData.content timeline=ProjectAboutData.timeline type=ProjectAboutData.type role=ProjectAboutData.role tool=ProjectAboutData.tool)

        ProjectContainer
          // Problems
          OneColumnTitle()
            Quote(content=ProblemsData.quote)
          OneColumnHeading(num=ProblemsData.num heading=ProblemsData.heading title=ProblemsData.title)
            OneColumnElementOrderList
              OneColumnElementListItem(title=ProblemsData.listTitle content=ProblemsData.listContent)
              OneColumnElementListItem(title=ProblemsData.listTitle2 content=ProblemsData.listContent2)
              OneColumnElementListItem(title=ProblemsData.listTitle3 content=ProblemsData.listContent3)

          // Role
          OneColumnHeading(num=RoleData.num heading=RoleData.heading content=RoleData.content)
            OneColumnElementTitle.mt-12.mb-4(title=RoleData.title)
            OneColumnElementOrderList
              OneColumnElementListItem(title=RoleData.listTitle content=RoleData.listContent)
              OneColumnElementListItem(title=RoleData.listTitle2 content=RoleData.listContent2)

          // Tools & System Structure
          BlockWideImage(image_url=ToolsSketchData.image_url image_alt=ToolsSketchData.image_alt)
          //- Sketch
          OneColumnHeading(num=ToolsSketchData.num heading=ToolsSketchData.heading title=ToolsSketchData.title content=ToolsSketchData.content)
            OneColumnElementTitle.my-8(content=ToolsSketchData.content2)
          //- System Structure
          OneColumnHeading(title=ToolsSystemData.title content=ToolsSystemData.content)
          //TODO: 手機版本時圖片太小
          BlockWideImage(image_url=ToolsSystemData.image_url image_alt=ToolsSystemData.image_alt)
          OneColumnTitle()
            OneColumnElementOrderList
              OneColumnElementListItem(title=ToolsSystemData.listTitle content=ToolsSystemData.listContent)
              OneColumnElementListItem(title=ToolsSystemData.listTitle2 content=ToolsSystemData.listContent2)
              OneColumnElementListItem(title=ToolsSystemData.listTitle3 content=ToolsSystemData.listContent3)
              OneColumnElementListItem(title=ToolsSystemData.listTitle4 content=ToolsSystemData.listContent4)
          //- Zeroheight
          OneColumnHeading(title=ToolsZeroData.title content=ToolsZeroData.content)
          BlockWideImage(image_url=ToolsZeroData.image_url image_alt=ToolsZeroData.image_alt)
          BlockWideImage(image_url=ToolsZeroData.image_url2 image_alt=ToolsZeroData.image_alt2)

          // Design Detail
          OneColumnHeading(num=DesignLabelData.num heading=DesignLabelData.heading title=DesignLabelData.title)
          BlockWideImage(image_url=DesignLabelData.image_url image_alt=DesignLabelData.image_alt)
          //- Research
          OneColumnTitle(title=DesignResearchData.title content=DesignResearchData.content)
          //- Definition
          OneColumnHeading(title=DesignDefhData.title content=DesignDefhData.content)
            OneColumnElementTitle.my-8(content=DesignDefhData.content2)
          BlockWideImage(image_url=DesignDefhData.image_url image_alt=DesignDefhData.image_alt)
          //- Design
          OneColumnHeading(title=DesignLibData.title content=DesignLibData.content)
          BlockWideImage(image_url=DesignLibData.image_url image_alt=DesignLibData.image_alt)
          OneColumnElementTitle.my-16(content=DesignLibData.content2)
            Video
          BlockWideImage(image_url=DesignLibData.image_url2 image_alt=DesignLibData.image_alt2)
          // BlockFullImage(image_url=DesignLibData.image_url3 image_alt=DesignLibData.image_alt3)
          //- Test
          div#bgColorChange
            OneColumnHeading(title=DesignTestData.title content=DesignTestData.content)
              OneColumnElementTitle.my-8(content=DesignTestData.content2)
            BlockWideImage(image_url=DesignTestData.image_url image_alt=DesignTestData.image_alt)
            BlockWideImage(image_url=DesignTestData.image_url2 image_alt=DesignTestData.image_alt2)
            BlockWideImage(image_url=DesignTestData.image_url3 image_alt=DesignTestData.image_alt3)
            BlockFullImage(image_url=DesignTestData.image_url4 image_alt=DesignTestData.image_alt4)
          //- Next
          OneColumnHeading(title=DesignNextData.title content=DesignNextData.content)

          //What did I learn
          OneColumnHeading(num=LearnData.num heading=LearnData.heading title=LearnData.title content=LearnData.content)
            OneColumnElementTitle.my-8(content=LearnData.content2)
            OneColumnElementTitle(content=LearnData.content3)

      ProjectNext(index=order1.text)


  `
}
